.mobile-drawer{
    height: 100%;
    width: 100%;
    background:url("../assets/landing_home_background.png");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 1.2rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mobile-drawer-header{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.drawer-top-btn{
    background-color: #67276f;
    display: flex;
    justify-content: center;
    padding: .4rem;
    border-radius: 5px;
    svg{
        font-size: 2rem;
    }
}

.drawer-header{
    font-weight: 800;
    font-size: 2rem;
}

.mobile-drawer-btn{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap:1.5rem
}

.mobile-menus{
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-weight: 600;
    font-size: 1.5rem;
}