@import '../node_modules/@syncfusion/ej2-base/styles/material.css'; 
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-documenteditor/styles/material.css";
@import url("../src/css/index.scss");

/* Theme setup  */
:root {
  --main-bg-color: #f5f5ff;
  --main-hover-color: #f9f9ff;
  --main-text-color: #101828;
  --body-text-color-600: #475467;
  --body-text-color-500: #667085;
  --body-text-color-200: #98A2B3;
  --body-text-color-100: #D0D5DD;
  --color-gradient-light: linear-gradient(90deg, rgb(92, 164, 251, 0.1) 9%, rgb(130, 63, 249, 0.1) 40.5%, rgb(188, 11, 243, 0.1) 68%, rgb(237, 12, 233, 0.1) 100%);
  --color-gradient: linear-gradient(90deg, #5CA4FB 9%, #823FF9 40.5%, #BC0BF3 68%, #ED0CE9 100%);
  --radius-xl: 12px;
  --radius-lg: 8px;
  --icon-color-yellow: #FEC53D;
  --icon-color-green: #4AD991;
  --icon-color-purple: #7A5AF8;
  --icon-color-pink: #DD2590;
  --background-color-yellow: #faf1d6;
  --background-color-green: #d8f3e6;
  --background-color-purple: #d9d2f4;
  --background-color-pink: #f7e0ee;
  --secondary-50: #EDE0FF;
  --secondary-100: #D9BFFF;
  --secondary-200: #C49EFF;
  --secondary-300: #B07DFF;
  --secondary-400: #9B5CFF;
  --secondary-500: #9F25F6;
  --secondary-600: #8C1ED9;
  --secondary-700: #7917BD;
  --secondary-800: #6610A0;
  --secondary-900: #530984;
  --neutral-50: #F9F9F9;
  --neutral-100: #F2F4F7;
  --neutral-200: #EAECF0;
}

/* New color, not from design, to replace cases when adding gradient is not possible*/
/*
50: #EDE0FF (Very light)
100: #D9BFFF (Light)
200: #C49EFF (Lighter)
300: #B07DFF (Light-medium)
400: #9B5CFF (Medium)
500: #9F25F6 (Original)
7. 600: #8C1ED9 (Dark-medium)
700: #7917BD (Darker)
800: #6610A0 (Dark)
10. 900: #530984 (Very dark) 
*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.file-container {
  width: 100%;
  height: 100%;
  border-radius: 20px; 
  overflow: hidden; 
}

.file-container a {
  display: block; 
  border-radius: inherit;
  
}

.file-container img, .file-container video, .file-container .unknown-file {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  cursor: pointer;
  object-fit: cover;
}

.unknown-file{
  color: red;
  text-align: center;
  display: flex;
  align-items: center;

}

.file-container iframe {
  width: 100%;
  height: 10rem;
  border-radius: inherit; 
  border:2px solid black;
  display: block;
  overflow: hidden;
  cursor: pointer;
}

/* New UI */
.gradient-circle {
  width: 36px;
  height: 36px;
  border-radius: 200px;
  background: var(--color-gradient);
}

.gradient-circle-small {
  width: 28px;
  height: 28px;
  border-radius: 200px;
  background: var(--color-gradient);
}

.gradient-circle-light {
  width: 44px;
  height: 44px;
  border-radius: 200px;
  background: var(--color-gradient-light);
}

.step-circle {
  width: 28px;
  height: 28px;
  border-radius: 200px;
  background: white;
  border: 1px solid var(--neutral-200);
}

.gradient-button {
  background: var(--color-gradient);
  border-radius: var(--radius-lg) !important;
  box-shadow:  none !important;
  font-weight: 600 !important;
  height: 40px !important;
  white-space: nowrap; 
  min-width: auto;
  width: fit-content;
}

.gradient-button-light {
  background: var(--color-gradient-light) !important;
  color: var(--secondary-700) !important;
  border-radius: var(--radius-lg) !important;
  box-shadow:  none !important;
  font-weight: 500 !important;
  height: 40px !important;
  white-space: nowrap; 
  min-width: auto;
  width: fit-content;
}

.gradient-text {
  background: var(--color-gradient);
  -webkit-background-clip: text;
  background-clip: text; /* Add this line for compatibility */
  -webkit-text-fill-color: transparent;
}

.gradient-icon {
  stroke: var(--color-gradient);
}

.icon {
  stroke-width: 1.7;
  color: white;
}

.box {
  border-radius: var(--radius-xl) !important;
  background: #FFF;
  padding: 20px 16px 20px 20px;
  width: 100%;
}

.icon-box {
  width: 44px;
  height: 44px;
  border-radius: var(--radius-lg);
  padding: 10px;
}

.icon-colored {
  stroke-width: 1.7;
  align-items: center;
  justify-items: center;
}

.campaign-image {
  width: 64px;
  height: 64px;
  border-radius: 100px;
  border: 1px solid var(--body-text-color-200);
  object-fit: cover;
}

.campaign-image-large {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid var(--body-text-color-200);
  object-fit: cover;
}
.profile-image-large {
  min-width: 55px;
  width: 55px;
  min-height: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
}
.profile-image {
  min-width: 44px;
  width: 44px;
  min-height: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
}

.creator-insights-container {
  display: flex;
padding: 16px;
align-items: center;
gap: 20px;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--linear-10, #4D8DFF);
background: linear-gradient(87deg, rgba(77, 141, 255, 0.06) 2.92%, rgba(195, 52, 235, 0.06) 99.35%);
}

.profile-image-medium {
  min-width: 40px;
  width: 40px;
  min-height: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--body-text-color-200);
  object-fit: cover;
}

.tiktok-embed-container iframe{
  max-width: 100%;
}

.profile-image-small {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: 50%;
  border: 1px solid var(--body-text-color-200);
  object-fit: cover;
}

.active-dot {
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: var(--icon-color-green);
}

.inactive-dot {
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: var(--icon-color-pink);
}

.outlined-button {
  border-radius: var(--radius-lg) !important;
  box-shadow:  none !important;
  font-weight: 600 !important;
  height: 40px !important;
  white-space: nowrap; 
  min-width: auto;
  width: fit-content;
}

.outlined-button-small {
  border-radius: var(--radius-lg) !important;
  box-shadow:  none !important;
  font-weight: 500 !important;
  height: 25px !important;
  white-space: nowrap; 
  min-width: auto;
  width: fit-content;
}

.column-header {
  color: var(--body-text-color-600);
  font-weight: 600;
  font-size: 14px;
  background-color: var(--hover-card-color);
}

.creator-list-item {
  cursor: pointer;
  padding: 10px; 
  border-radius: var(--radius-lg);
}
.creator-list-item:hover {
  background-color: var(--neutral-50);
}

.separator-dot {
  width: 4px;
  height: 4px;
  border-radius: 100px;
}

.chat-bubble-user {
  background-color: var(--secondary-500);
  border-radius: 16px 0px 16px 16px;
  padding: 10px;
  max-width: 45%;
  width: fit-content;
  word-wrap: break-word;
}

.chat-bubble-partner {
  background-color: var(--main-bg-color);
  border-radius: 0px 16px 16px 16px;
  padding: 10px;
  max-width: 45%;
  width: fit-content;
  word-wrap: break-word;
}

.content-status-box {
  border-radius: var(--radius-xl);
  background-color: var(--neutral-50);
  padding: 15px;
  border: 1px solid var(--neutral-200);
}

.item {
  border-radius: var(--radius-lg);
  border: 1px solid var(--neutral-200);
  padding: 10px;
  cursor: pointer;
  text-align: center;
}

.item:hover {
  background-color: var(--neutral-50);
}

.item-selected {
  background: var(--color-gradient-light);
  border-radius: var(--radius-lg);
  border: 1px solid var(--secondary-300);
  padding: 10px;
  cursor: pointer;
  text-align: center;
}

.item-selected:hover {
  background-color: var(--neutral-50);
}

.item-yn {
  width: 70px;
}

.react-multi-carousel-dot button {
  transition: all 0.5s ease;
}

.landscape-carousel-item  {
  scale: 0.9!important;
  transition: .3s;
}

.landscape-carousel-item--active{
  scale: 1!important;
}

.landscape-carousel-container {
  padding-bottom: 20px;
}

.landscape-carousel-slider {
  padding-bottom: 15px !important;
}