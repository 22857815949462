.discovery-table-body{
    height: max-content;
    width: 100%;
    margin-top: 1rem;
    border-radius: 15px;
}

.name-search{
    width: 300px;
    background-color: white;
    border-radius: 8px;
    .MuiOutlinedInput-root{
        padding: 0px 10px;
        height: 40px;
        
    }
    .MuiInputBase-input {
        padding: 10px 14px; 
      }
}

.discovery-table{
    background-color: white;
    margin-top: 1rem;
    border-radius: 15px;
}

.tbl-filter{
    padding: 1rem
}

.search-export-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
  .location-icon{
    width: '1em';
    height: '1em';
  }

  .discover_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .drawer-header{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
  }

  .filter-header{
    color:"black";
    font-size: 1rem; 
    font-weight:600;
    svg{
      margin-right: 10px;
    }
  }

  .filter-body{
    display: flex;
    padding: 1rem;
    flex-direction: column;
    gap: .5rem;
  }

  .title-text{
    font-weight: bold;
  }

  .btn-body{
    display: flex;
    align-items: center;
    svg{
      margin-right: 10px;
    }
  }

  .checkbox-container {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 10px;
    padding-left: .5rem;
    span{
      font-size: .8rem;
    }
    
  }

  .filter-bottom{
    padding: 1rem;
    width: 58%;
    display: flex;
    justify-content: space-between;
    float: right;
  }

  .my-list-content{
    display: grid;
    gap: 5px;
    width: 100%;
    flex-direction: column;
    align-items: start;
    .field-title{
      font-size: .9rem;
      font-weight: bold;
    }
    .mrg-top{
      margin-top: 10px;
    }
    .field-sub-title{
      font-weight: 400;
      font-size: .8rem;
      color:#667085;
    }

  }

  .mylist-option{
    padding: .5rem ;
    border: 1px #EAECF0 solid;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: .5rem;
    border-radius: 10px;
  }

  .checked-option{
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #4D8DFF, #C334EB) 2 !important;
    border-width: 1px !important
  }

  .dialog-header{
    display: flex;
    align-items: center;
    .title-main{
      font-weight: 600;
      font-size:1.15rem
    }
    .title-sub{
      font-weight: 400;
      font-size: 1rem;
      color:#667085;
      margin-left:15px;
    }

  }

  .footer-bar{
    position: fixed;
    bottom: 0;
    left: 32%;
    height: 50px;
  }