@import url("./campaign/discoverCreator.scss");
@import url("./landing.scss");
@import url("./brand-carousel.scss");
@import url("./landing-footer.scss");
@import url("./mobile-drawer.scss");
@import url("./campaign/landscape.scss");

@font-face{
    font-family: 'Bricolage Grotesque' ;
    src: url("../assets/fonts/BricolageGrotesque-Regular.ttf") format('truetype');
}


.body-title {
    font-weight: 800 !important;
    margin-bottom: 20;
    font-size: 1.5rem !important;
}

.content-body {
    background-color: #F5F6FA;
    padding: 20px;
    width: 100%;
}

.custom-button {
    background-color: white;
    color: black !important;
    border: 1px solid #D0D5DD !important;
    padding: 8px 16px;
    text-transform: none !important;
    transition: all 0.3s ease-in-out;
    border-radius: 10px !important;
    &.text-white{
        color: white !important
        
    }
    
}

.custom-button:hover,
.custom-icon-button:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    background-color: white !important;
}

.custom-button{
    &.text-white:hover{
        background-color: transparent !important;
    }
}

.custom-button .MuiButton-startIcon,
.custom-icon-button .MuiButton-startIcon {
    margin-right: 8px;
}

.grd-border-image{
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #4D8DFF, #C334EB) 2 !important;
    border-width: 1px !important
}

.btn-icon {
    font-size: .8rem !important;
}

.custom-icon-button{
    border: 1px solid #D0D5DD;
    border-radius: 5px ;
    padding: 8px;
    cursor: pointer;
}

.cutom-b-icon{
    transition: none !important;
}

.align-item-center {
    align-items: center;
}

.column{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
}

.checkbox{
    -webkit-appearance: none;
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 5px;
    background: white;
    border: 1px solid #D0D5DD;
    cursor: pointer;
    margin-right: 10px;
}

.checkbox:checked{
    background: var(--secondary-600);
}

.checkbox:checked::before{
    content: '';
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 70%;
    pointer-events: none;
    background-image: url(../assets/check.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.pointer-curser{
    cursor: pointer;
}

.grd-bg-btn{
    background: linear-gradient(to right, #4D8DFF, #C334EB ) !important;
    color: white !important;
}

.row-info{
    font-size: .7rem;
}
  
.w-50{
    width: 50%;
}
.w-25{
    width: 25%;
}
.w-23{
    width: 23%;
}
.w-70{
    width: 70%;
}

.w-30{
    width: 30%;
}

.d-none{
    display: none;
}

.mb-1{
    margin-bottom: .5rem;
}