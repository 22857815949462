.section-header-container{
    display: flex;
    flex-direction: row;
    gap:1rem;
    align-items: start;
    padding: 2rem 0;
}

.section-header-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-icon-container{
    background: linear-gradient(to right, #5CA4FB,#823FF9,#BC0BF3,#ED0CE9);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    img{
        height: 100%;
        width: auto;
    }
}

.header-content{
    display: flex;
    flex-direction: column;
    gap: .1rem;
    align-items: start;
    .header-title{
        font-weight: 600;
        font-size: 1rem;
        color: #101828;
    }
    .header-subtitle{
        color: #475467;
        font-size: .8rem;
    }
}

.brand-field{
    font-weight: 600 ;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:.5rem;
    img{
        width: 40px ;
        height: auto;
        border-radius: 50%;
    }
}

.info-card{
    width: 100%;
    border-radius: 10px;
    background-color: white;
}

.info-card-header{
    padding: .5rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.info-card-title{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    img{
        height: 25px;
        width: 25px;
    }
}

.info-body-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    align-items: center;
    padding: .5rem 1rem;
}

.text-black{
    font-weight: 600;
    color:#000000
}

.text-light{
    font-weight: 400;
    color:#475467 ;
}

.star-row{
    display: flex;
    flex-direction: row;
    gap: 5px;
    img{
        width: 15px;
        height: auto;
    }
}

.social-listing{
   display: flex;
   flex-direction: column;
   gap: .8rem;
   height: 87vh;
   overflow-y: scroll;
   padding: 3px;
}

.trending-now{
    img{
        height: auto;
        width: 100%;
    }
}

.acocrdion-header{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.accordion-order{
    background-color:#F6EDFD ;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    text-align: center;
    align-content: center;
    font-size: .8rem;
    color: #C334EB;
}
.accordion-header-container{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 100%;
}
.accordion-title{
    font-weight: 600;
    font-size: 1rem;
}

.accordion-desc{
    color: #344054;
    font-weight: 400;
    font-size: .8rem;
}

.body-desc{
    padding-bottom: 1rem;
    color: #344054;
    font-weight: 400;
    font-size: .8rem;
}

.accordion-footer{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap:2rem;
    align-items: center;
    padding: 0 .5rem;
    padding-bottom: .5rem;
}

.right-footer{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.left-footer{
    color: #101828;
    font-weight: 500;
    font-size: .8rem;
}

.full-row{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem 0;
}

.half-card{
    width: 50%;
    border-radius: 10pc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: .8rem;
    align-items: center;
}

.header-content{
    font-weight: 600;

}
.info-section{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    &.align-center{
        align-items: center;
    }
}
.info-row-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:.5rem
}

.left-info{
    display: flex;
    flex-direction: column;
    align-items: end;
    gap:.5rem
}

.left-icons{
    display: flex;
    flex-direction: row;
    gap: .5rem;
}

.post-content{
    color: #475467;
    font-weight: 400;
    font-size: .6rem;
}
  
.row-info{
    a{
        text-decoration: none !important;
        color: inherit;
    }
}

.h-270{
    height: 300px;
    overflow-y: scroll;
}

.p-40{
    padding: 0 20px;
}
.h-300{
    height: 300px !important;
}

.h-200{
    height: 200px !important;
}

.h-250{
    height: 250px !important;
}

.card-header-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.card-header-filter{
    padding-right: 1rem;
}

.creator-img{
    
    width: 100%;
    height: 20vh;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 10px 10px 0px 0px;
    }
}

.creator-details{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}

.creator-other{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.info-detail{
    font-size: .8rem;
}