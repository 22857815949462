  .carousel-container{
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
  }
  .carousle-body,.carousle-info{
    width: 50%;
  }
  .carousle-body{
    padding: 1rem;
    height: 25rem;
  }

  .image-container{
    width: 15rem;
    height: 20rem;

  }

  .person-img{
    object-fit: cover;
              height: 100%;
              width: 100%;
              border-radius: 15px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .person-image{
    height: 50%;
    width: auto;
    display: flex;
    align-items: center;

  }

  .mycontainer{
    height: max-content;
    width: 100%;
  }

  .carousle-info{
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
   
  }

  .brand-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    padding-top: 2rem;
    img{
        height: auto;
        width: 7rem;
    }
    .brand-name{
        text-transform: uppercase;
        color:#24272A;
        letter-spacing: normal;
        font-weight: 600;
    }
    
  }

  .brand-detail{
    color: #111928;
    font-weight: 500;
    font-size: 1em;   
   }

   .brand-person{
    display: flex;
    gap: 10px;
    flex-direction: column;
   }

   .person-name{
    font-weight: 600;
    font-size: 1.1em;
    color:#111928
   }

   .person-post{
    color: #111928;
    font-weight: 400;
    font-size: .8em;
   }

   .navigators{
    display: flex;
    flex-direction: row;
    gap: 15px;
   }

   .icon-button{
    padding: .5rem;
    border: 1px solid #98A2B3 ;
    border-radius: 50%;
    display: flex;
    align-items: center;
    color: #141B34;
    height: 35px;
    width: 35px;
    cursor: pointer;
    &:hover{
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

    }
    svg{
        width: 100%;
        height: 100%;
    }
   }

   .passive-iamge{
    filter: grayscale(50%);
    opacity: 0.5;
   }

@media only screen and (max-width: 768px) {


   .carousel-container{
    flex-direction: column;
   }
   .carousle-body,.carousle-info{
    width: 100%;
   }
   .image-container{
    width: 10rem;
    height: 15rem;
    padding: 1rem;
    
   }
   .carousle-body{
    height: max-content;
   }
   .navigators{
    padding-bottom: 4rem;
   }
   .carousle-info{
    padding: 0 1rem;
   }

  }
  