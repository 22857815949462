.gradient-animation {
  background-color: white;

  background-size: 350% 350%;

  -webkit-animation: Wave 5s ease infinite;
  -moz-animation: Wave 5s ease infinite;
  animation: Wave 5s ease infinite;
}

@-webkit-keyframes Wave {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-moz-keyframes Wave {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@keyframes Wave {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}

.line:after {
  content: "";
  position: absolute;
  z-index: 10;
  top: 24px;
  bottom: 0;
  left: 50%;
  border-left: 1px solid black;
  transform: translate(-50%);
  height: 300px;
}
