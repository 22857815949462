.review-center{
    display: flex;
    background-color: rgb(196, 195, 208, .2);
    padding:1rem;
    flex-direction: column;
    display: none;
    margin:1.5rem 0rem;
  
}
.review-center.opened{
    display: block;
    border-radius: 0.2cm;

}
.row{
    display: flex;
    align-items: center;
    border-radius: 0.1cm;
    width: 100%;
}

.right-content{
    display: flex;
    justify-content: end;
}