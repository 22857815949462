.rcw-widget-container {
  position: relative;
  z-index: 0;
}

.rcw-launcher {
  display: none;
}

.rcw-conversation-container > .rcw-header {
  background-color: black;
}
