.footer-container {
    background-color: #180038;
    padding: 2rem 5rem 2rem 2rem;
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    color: #F9FAFB
}

.gigl-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.contact-menu {
    flex: 1;
}

.menu-container {
    display: flex;
    flex-direction: row;
    flex: 2;
    justify-content: space-around;
}

.footer-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 4rem;
}

.gigl-profile {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 60%;
}

.gigl-social {
    display: flex;
    gap: .5rem;
    flex-direction: row;
}

.social-container {
    height: 34px;
    width: 34px;
    background-color: #493662;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white !important;
    cursor: pointer;

    img {
        height: 60%;
        width: auto;
    }

}

.white-icon {
    fill: white !important;
}

.gigl-title {
    font-weight: 800;
    font-size: 2rem;
    text-transform: capitalize;
}

.gigl-info {
    font-weight: 400;
    font-size: .8rem;
    color: #EAECF0;
}

.social-container:hover {
    background: linear-gradient(to left, #4D8DFF, #C334EB);
    box-shadow: 0px 4px 8px rgb(255, 255, 255, .1)
}

.footer-end {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: .8rem;
    color: #D1D5DB;
}

.menu-title {
    font-family: 'Bricolage Grotesque', sans-serif;
    font-weight: 700;
    color: #F9FAFB;
    font-size: 1rem;
}

.menu-list {
    width: 100%;

    ul {
        padding: 0;
        list-style-type: none;
        font-weight: 400;
        font-size: .8rem;
    }

    li {
        padding: .2rem 0;
    }
}

.flex-start {
    display: flex;
    justify-content: flex-start;
    gap: .5rem
}

@media only screen and (max-width: 768px) {
    .footer-container{
        padding: 2rem;
    }
.footer-content{
    flex-direction: column;
}
.gigl-profile{
    width: 100%;
}
.gigl-menu{
    flex-direction: column;
    gap: 2rem;
}
.menu-container{
    justify-content: space-between;
}
.footer-end{
    flex-direction: column;
    gap:1rem
}
}