svg#freepik_stories-following:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-following.animated #freepik--speech-bubble--inject-2 {
  animation: 3s Infinite linear heartbeat;
  animation-delay: 1s;
}
svg#freepik_stories-following.animated #freepik--Icons--inject-2 {
  animation: 3s Infinite linear wind;
  animation-delay: 0s;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  30% {
    transform: scale(1);
  }
  40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes wind {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(1deg);
  }
  75% {
    transform: rotate(-1deg);
  }
}
