.chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
.y-label {
    transform: rotate(-90deg);
    white-space: nowrap;
    font-size: 1rem;
    width: 3%;
  }