.landing-container {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    width: 100%;
}

.burger-menu{
    display: none;
}

.mobile-icons{
    display: none;
}

.landing-home {
    width: inherit;
    background-image: url("../assets/landing_home_background.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 5%;
    overflow: hidden;
}

.landing-topbar {
    color: white;
    display: flex;
    justify-content: space-between;
    padding-right: 5%;
    padding-top: 1%;
}

.company-title {
    font-size: 2rem;
    font-weight: 800;
}

.landing-topbar-menu {
    display: flex;
    gap: 2rem;
    align-items: center;

    .menu-button {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
    }
}

.landing-home-container {
    padding-top: 2%;
    display: flex;
    justify-content: start;
    min-height: 60vh;
    max-height: max-content;

    .home-left,
    .home-right {
        width: 50%;
    }

    .home-right {
        background-image: url("../assets/hero-background-main.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        resize: both;
        position: relative;
    }

    .hero-chart {
        position: absolute;
        bottom: 10%;
        left: -1em;
        height: 12em;
    }

    .hero-girl {
        position: absolute;
        bottom: 0;
        left: 8em;
        height: 100%;
        width: auto;
    }

    .home-left {
        p {
            color: white;
            font-weight: 400;
            font-size: 1rem;
            width: 80%;
            padding-top: .5rem;
        }

        .home-button-bar {
            display: flex;
            gap: 15px;
            padding-bottom: 5rem;
        }
    }

    .home-title {
        font-family: 'Bricolage Grotesque', sans-serif;
        color: white;
        font-weight: 800;
        font-size: 3rem;

        .simple-text {
            display: flex;
            align-items: center;
            gap: 5px;
            line-height: normal;

            img {
                height: 2.5rem;
                width: 2.5rem;
            }
        }

        .text-underline {
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: start;
            align-items: flex-start;
            width: 100%;
            line-height: 1;

            img {
                width: 27%;
            }
        }

    }
}

.landing-find {
    display: flex;
    padding-top: 2rem;
    width: 100%;
    flex-direction: column;

    .find-title-body {
        width: 100%;
        display: flex;
        padding: 3rem 5rem;
    }

    .find-title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        img{
            height: 65px;
            width: auto;
        }

        .left-bottom-icon {
            display: flex;
            align-items: end;
        }
        .right-bottom-icon{
            display: flex;
            align-items: start;
        }

    }

    .slider-body {
        padding: 2rem 0;
        width: 100%;

        .slider-container {
            width: 15rem;
            height: 20rem;
            position: relative;
            border-radius: 15px;
            overflow: hidden;
            background-color: #fff;
            padding: 1rem;

            .slider-img {
                object-fit: cover;
                height: 100%;
                width: 100%;
                border-radius: 15px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            }

            .slider-details {
                position: absolute;
                bottom: 1.5rem;
                left: 30px;
                color: #fff;

                .slider-name {
                    font-size: 20px;
                    font-family: 'Bricolage Grotesque', sans-serif;
                    margin: 0;
                }

                .slider-location {
                    font-size: 11px;
                    display: flex;
                    align-items: center;

                    img {
                        height: 12px;
                        width: 12px;
                        margin-right: 10px;
                    }
                }
            }

            .slider-links {
                position: absolute;
                right: 30px;
                bottom: 1.5rem;
                display: flex;
                flex-direction: column-reverse;

                .social-link {
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    background-color: rgba(0, 0, 0, 0.5);
                    padding: 8px;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                    margin-top: .5rem;
                    display: flex;
                    align-items: center;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.7);
                    }

                    .social-img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

            }

        }
    }
}

.find-title {
    font-family: 'Bricolage Grotesque', sans-serif;
    color: black;
    font-weight: 800;
    font-size: 3rem;
    line-height: normal;
    align-items: center;
    display: flex;
    flex-direction: column;

    .title-relative {
        position: relative;
        display: inline-block;
        width: 100%;

        .relative-chip {
            position: absolute;
            left: 25%;
            top: -35%;
            background-color: #FFE600;
            padding: .5rem;
            font-size: 12px;
            width: max-content;
            border-radius: 1rem;
            transform: rotate(-10deg);

            &.flip {
                transform: rotate(10deg)
            }

            &.toolkit {
                left: 50%
            }
            &.brands{
                left: 10%;
                top:-10px
            }
            &.community{
                left: 18%;
                top: -15%;
            }
        }
    }

}
.text-combination {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    .text-underline {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: start;
        align-items: flex-start;
        width: 100%;
        line-height: .9;
        z-index: 1;

        img {
            width: 100%;
            height: 8px;
            z-index: -1;
        }
    }

}
.toolkit-title-container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 3rem 0rem;
}

.color-container {
    padding: 3rem;
    margin: 1rem 3rem;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    align-content: start;
    
    .info-container{
        width: 50%;
    }
    .image-container{
        width: 50%;
        display: flex;
        justify-content: end;
    }
    &.left{
        flex-direction: row-reverse;
        .image-container{
            justify-content: start;
        }
    }
    &.blue {
        background-color: #EFF7FF;
    }
    &.purple {
        background-color: #F2EFFE;
    }
    &.pink {
        background-color: #FCE9F7;
    }
    .colo-image {
        height: 110%;

    }
    .info-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding-right: 2rem;
    }
    .color-title {
        font-size: 2.5rem;
        color: black;
        font-family: 'Bricolage Grotesque', sans-serif;
        font-weight: 800;
        line-height: normal;
    }

    .color-bullet{
        display: flex;
        width: 100%;
        justify-content: flex-start;
        flex-direction: row;
        padding-top: 1.5rem;
    }

    .bullet-icon{
        background-color:#FFFFFF ;
        height: max-content;
        width: max-content;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center;
        padding: .3rem;
        border-radius: 50%;
    }
    .bullet-info{
        padding-left: 1rem;
    }

    .bullet-title{
        font-family: 'Bricolage Grotesque',sans-serif;
        font-weight: 600;
        font-size: 1rem;
        padding-bottom: .5rem;
        color: black;
    }

    .bullet-subtitle{
        font-size: .8rem;
        color: #475467;
        font-weight: 400;
    }
}

.trusted-brands{
    background-color: #F2F4F7;
    padding: 2rem 0;
}

.trusted-title{
    font-family: 'Bricolage Grotesque', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
}

.brand-carousel-container{
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.brand-container{
    background-color: #FFFFFF;
    width: 7em !important;
    height: 7em !important;
    border-radius: 50%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;

}

.brand-image{
    width: 70%;
    height: auto;
    &.small{
        width: 55% !important;
    }
}



.slick-slide{
    height: 7rem !important;
    width: 7rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-left: 2rem !important;

}

.myClassName{
    height: max-content !important;
}

.creator-community{
    padding: 2rem 5rem;
}

.find-title-container{
    display: flex;
    justify-content: space-between;
}

.left-center-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-bottom-icon{
    display: flex;
    justify-content: end;
    align-items: end;
}

.community-image{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    img{
        width: 70%;
        height: auto;
    }
}

.join-community{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 58%;
    .curly-wave{
        height: 50px;
    }
    img{
        height: 100%;
        width: auto;
    }
}

@media only screen and (max-width: 768px) {
    .landing-home{
        background-position: left;
    }
    .landing-topbar-menu{
        display: none;
    }
    .landing-home-container{
        flex-direction: column;
        .text-underline{
            img{
                width:45% !important
            }
        }
        .home-button-bar{
            flex-direction: column;
            width: 95%;
            padding-bottom: 2rem !important;
        }
        .home-left,.home-right{
            width:100% !important
        }
        .landing-topbar-menu{
            display: none;
        }
        .home-title{
            font-size: 2.5rem !important;
        }
        .home-right{
            height: 40vh;
        }
        .hero-girl{
            left:3rem
        }
    }
    .relative-chip{
        top:-92% !important;
        transform: rotate(-5deg) !important;
        &.flip {
            transform: rotate(5deg) !important;
        }
        &.toolkit{
            left: 25% !important;
        }
    }
    .find-title{
        font-size: 1.5rem;
    }
    .landing-find{
        padding-top: 4rem;
        .text-underline{
            img{
                width: 145px !important;
            }
        }
        
        .find-title-container{
            flex-direction: row;
            img{
                width: 35px;
                height: auto;
            }
        }
        
        .find-title-body {
            width: 100%;
            display: flex;
            padding: 0;
        }
        .title-relative{
            text-align: center;
        }
        
    }
    .slider-container{
        width: 13rem !important;
    }
    .toolkit-title-container{
        text-align: center;
        &.second-title{
            .text-underline{
                img{
                    width: 115px;
                }
            }
        }
        .text-underline{
            img{
                width: 125px;
            }
        }
    }

    .color-container{
        flex-direction: column;
        padding: 2rem;
        margin: 1rem;
        width: 90%;
        gap: 2rem;
        &.left{
            flex-direction: column;
        }
        .info-container,.image-container,.colo-image{
            width: 100% !important;
        }
        .color-title{
            font-size: 1.5rem;
            width: 100%;
        }
    }

    .trusted-brands{
        margin-bottom: 4rem;
    }

    .creator-community{
            padding: 0 1rem;
            .left-center-icon,.right-bottom-icon{
                display: none;
            }
            .find-title{
                width: 100%;
                text-align: center;
                .text-underline{
                    img{
                        width: 80%;
                    }
                }
            }
            .community-image{
                width: 100%;
                img{
                    width: 100%;
                height: auto;
                }
            }
            .mobile-icons{
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                img{
                    height: 20px;
                    width: auto;
                }
            }
            .join-community{
                flex-direction: column-reverse;
                gap:1rem;
                width: 100%;
                img{
                    width: 25px;
                    height: auto;
                }
                .community-btn{
                    margin-left: 10%;
                    width: 80%;
                }
            }
    }
    .landing-topbar{
        align-items: center;
    }
    .burger-menu{
        display: flex;
        justify-content: center;
        background-color: #58205F ;
        width: 35px;
        height: 35px;
        padding: .2rem;
        cursor: pointer;
        img{
            width: 100%;
        }
    }
  }